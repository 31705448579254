class ActiveSection extends BaseComponent {
    constructor({
        scope,
        target
    }) {
        super({
            scope,
            target
        });
        
        // Array para almacenar todas las instancias de ScrollTrigger
        this.scrollTriggers = [];
        
        // Vincular eventos de Barba
        this._bindEvents();
    }

    set($el) {
        // Aquí guardaremos referencias específicas para cada elemento $el si es necesario
        this.$el = $el;
    }

    run($el) {
        this._stageSection($el);
    }
    
    _bindEvents() {
        // Escuchar eventos de transición de Barba
        window.$window.on('arts/barba/transition/start', () => {
            this._killScrollTriggers();
        });
        
        // Este evento ocurre después de que termina la transición
        window.$window.on('arts/barba/transition/end', () => {
            // Esperar un poco para que el DOM esté listo
            setTimeout(() => {
                this._reinitialize();
            }, 100);
        });
        
        // Este evento ocurre después de que se inicializan los componentes en la nueva página
        window.$window.on('arts/barba/transition/init/after', () => {
            // Esperar un poco para que el DOM esté listo
            setTimeout(() => {
                this._reinitialize();
            }, 100);
        });
        
        // Evento personalizado para reinicializar ActiveSection
        window.addEventListener('arts/active-section/reinitialize', () => {
            this._reinitialize();
        });
    }
    
    _reinitialize() {
        // Limpiar ScrollTriggers existentes
        this._killScrollTriggers();
        
        // Buscar de nuevo los elementos necesarios
        const $activeTitle = this.$scope.find('.active-title');
        
        if ($activeTitle.length) {
            // Reinicializar el componente con los elementos actuales
            this._stageSection();
        }
    }
    
    _killScrollTriggers() {
        // Eliminar todos los ScrollTriggers creados por este componente
        if (this.scrollTriggers && Array.isArray(this.scrollTriggers)) {
            this.scrollTriggers.forEach(trigger => {
                if (trigger && typeof trigger.kill === 'function') {
                    trigger.kill();
                }
            });
            
            // Vaciar el array
            this.scrollTriggers = [];
        } else {
            // Si no existe, inicializarlo
            this.scrollTriggers = [];
        }
    }

    _stageSection($el) {
        // get viewport height
        const getVh = () => {
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            return vh;
        }

        // get viewport width
        const getVw = () => {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            return vw;
        }

        // Primero matamos cualquier ScrollTrigger existente para evitar duplicados
        this._killScrollTriggers();

        // Encuentra todas las secciones con la clase 'stage'
        const stages = this.$scope.find('.stage');
        const navLinks = this.$scope.find('.active-title li');

        // Si no hay stages o navLinks, no hacer nada
        if (!stages.length || !navLinks.length) {
            return;
        }

        stages.each((index, stage) => {
            // Crear el ScrollTrigger y guardarlo en el array
            const trigger = ScrollTrigger.create({
                trigger: stage,
                start: 'top top+=50',
                end: () => `+=${stage.clientHeight+getVh()/1000}`,
                toggleClass: {
                    targets: navLinks[index],
                    className: 'is-active'
                },
                id: `active-section-trigger-${index}`
            });
            
            // Guardar el ScrollTrigger en el array
            this.scrollTriggers.push(trigger);
        });
    }
}









