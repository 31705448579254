class SwiperGallery {
    constructor() {
        this._swiper();
    }
    _swiper() {     
        if (document.querySelector('.swiper-gallery')) {
            const swiperGallery = new Swiper('.swiper-gallery', {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                    hide: false,
                },
                breakpoints: {
                    576: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 2.7,
                        spaceBetween: 30,
                    }
                }
            });
        }
    }
}





