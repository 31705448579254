class TabsPurpose {
    constructor() {
        this.tabsContainer = document.querySelector('.tabs-hover');
        this.links = document.querySelectorAll('.tabs-hover .links .title-link');
        this.contents = document.querySelectorAll('.tabs-hover .content > div');
        this.linksContainer = document.querySelector('.tabs-hover .links');
        this.contentContainer = document.querySelector('.tabs-hover .content');
        this.isMobile = window.innerWidth < 991;
        this.activeIndex = 0;
        this.originalStructure = {
            linksParent: this.linksContainer,
            contentParent: this.contentContainer
        };
        
        this._init();
        this._setupEventListeners();
        this._handleResize();
    }

    _init() {
        // Set first tab as active by default
        if (this.links.length > 0) {
            this.links[0].classList.add('active');
            this.contents[0].classList.add('active');
        }
        
        // Hide all non-active contents
        this.contents.forEach((content, index) => {
            if (index !== 0) {
                content.classList.add('hidden');
            }
        });
        
        // Create accordion structure for mobile if needed
        if (this.isMobile) {
            this._restructureForMobile();
            this._setupMobileAccordion();
        }
    }
    
    _restructureForMobile() {
        // Only proceed if we have the correct structure
        if (!this.linksContainer || !this.contentContainer) return;
        
        // Create mobile container if it doesn't exist
        let mobileContainer = document.querySelector('.tabs-hover .mobile-accordion');
        if (!mobileContainer) {
            mobileContainer = document.createElement('div');
            mobileContainer.classList.add('mobile-accordion');
            this.tabsContainer.appendChild(mobileContainer);
        }
        
        // Clear the mobile container
        mobileContainer.innerHTML = '';
        
        // For each tab, create an accordion item with its header and content
        this.links.forEach((link, index) => {
            const accordionItem = document.createElement('div');
            accordionItem.classList.add('accordion-item');
            
            // Clone the link as the header
            const header = link.cloneNode(true);
            header.classList.add('accordion-header');
            
            // Add plus/minus icon
            if (!header.querySelector('.toggle-icon')) {
                const toggleIcon = document.createElement('span');
                toggleIcon.classList.add('toggle-icon');
                toggleIcon.innerHTML = index === this.activeIndex ? '−' : '+';
                header.appendChild(toggleIcon);
            }
            
            // Clone the content
            const content = this.contents[index].cloneNode(true);
            content.classList.add('accordion-content');
            if (index !== this.activeIndex) {
                content.classList.add('hidden');
            }
            
            // Add header and content to the accordion item
            accordionItem.appendChild(header);
            accordionItem.appendChild(content);
            
            // Add the accordion item to the mobile container
            mobileContainer.appendChild(accordionItem);
            
            // Add event listener to the header
            header.addEventListener('click', (e) => {
                e.preventDefault();
                
                // Find the corresponding original link and trigger its click event
                this.links[index].dispatchEvent(new Event('click'));
                
                // Update this header's toggle icon
                const toggleIcon = header.querySelector('.toggle-icon');
                if (toggleIcon) {
                    toggleIcon.innerHTML = this.links[index].classList.contains('active') ? '−' : '+';
                }
            });
            
            // Add keyboard support
            header.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    header.click();
                }
            });
            
            // Add accessibility attributes
            header.setAttribute('tabindex', '0');
            header.setAttribute('role', 'button');
            header.setAttribute('aria-expanded', index === this.activeIndex ? 'true' : 'false');
        });
        
        // Show mobile accordion, hide desktop structure
        mobileContainer.style.display = 'block';
        this.linksContainer.parentElement.style.display = 'none';
    }
    
    _restructureForDesktop() {
        // Hide mobile accordion if it exists
        const mobileContainer = document.querySelector('.tabs-hover .mobile-accordion');
        if (mobileContainer) {
            mobileContainer.style.display = 'none';
        }
        
        // Show desktop structure
        this.linksContainer.parentElement.style.display = '';
    }
    
    _setupMobileAccordion() {
        // For each link, add a toggle icon if it doesn't have one
        this.links.forEach((link, index) => {
            if (!link.querySelector('.toggle-icon')) {
                const toggleIcon = document.createElement('span');
                toggleIcon.classList.add('toggle-icon');
                toggleIcon.innerHTML = index === this.activeIndex ? '−' : '+'; // Minus for active, plus for inactive
                link.appendChild(toggleIcon);
            }
            
            // If it's not the active item, hide its content in mobile view
            if (index !== this.activeIndex) {
                this.contents[index].classList.add('hidden');
            }
        });
    }

    _setupEventListeners() {
        // Click/tap event for all links
        this.links.forEach((link, index) => {
            // Click event for all devices
            link.addEventListener('click', (e) => {
                e.preventDefault();
                
                if (this.isMobile) {
                    // For mobile: toggle the current accordion item
                    if (this.activeIndex === index) {
                        // If clicking on the already active item, close it
                        this._toggleAccordionItem(index);
                    } else {
                        // Close the currently open item
                        if (this.activeIndex !== null) {
                            this._deactivateTab(this.activeIndex);
                        }
                        // Open the clicked item
                        this._activateTab(index);
                    }
                } else {
                    // For desktop: standard tab behavior
                    this._activateTab(index);
                }
                
                // Update mobile accordion if it exists
                this._updateMobileAccordion();
            });
            
            // Keyboard support for accessibility
            link.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    
                    if (this.isMobile) {
                        if (this.activeIndex === index) {
                            this._toggleAccordionItem(index);
                        } else {
                            if (this.activeIndex !== null) {
                                this._deactivateTab(this.activeIndex);
                            }
                            this._activateTab(index);
                        }
                    } else {
                        this._activateTab(index);
                    }
                    
                    // Update mobile accordion
                    this._updateMobileAccordion();
                }
            });
            
            // Add accessibility attributes
            link.setAttribute('tabindex', '0');
            link.setAttribute('role', this.isMobile ? 'button' : 'tab');
            link.setAttribute('aria-expanded', index === 0 ? 'true' : 'false');
        });
        
        // Handle window resize
        window.addEventListener('resize', this._handleResize.bind(this));
    }
    
    _updateMobileAccordion() {
        const mobileContainer = document.querySelector('.tabs-hover .mobile-accordion');
        if (!mobileContainer) return;
        
        // Update all accordion headers
        const accordionHeaders = mobileContainer.querySelectorAll('.accordion-header');
        accordionHeaders.forEach((header, index) => {
            // Update active state
            if (index === this.activeIndex) {
                header.classList.add('active');
                header.setAttribute('aria-expanded', 'true');
            } else {
                header.classList.remove('active');
                header.setAttribute('aria-expanded', 'false');
            }
            
            // Update toggle icon
            const toggleIcon = header.querySelector('.toggle-icon');
            if (toggleIcon) {
                toggleIcon.innerHTML = (index === this.activeIndex) ? '−' : '+';
            }
            
            // Update content visibility
            const content = header.nextElementSibling;
            if (content && content.classList.contains('accordion-content')) {
                if (index === this.activeIndex) {
                    content.classList.add('active');
                    content.classList.remove('hidden');
                } else {
                    content.classList.remove('active');
                    content.classList.add('hidden');
                }
            }
        });
    }
    
    _handleResize() {
        const wasDesktop = !this.isMobile;
        this.isMobile = window.innerWidth < 991;
        
        // If we've crossed the breakpoint, update the UI
        if (wasDesktop !== !this.isMobile) {
            if (this.isMobile) {
                // Switching to mobile: set up accordion
                this._restructureForMobile();
                this._setupMobileAccordion();
            } else {
                // Switching to desktop: clean up accordion elements
                this._restructureForDesktop();
                
                this.links.forEach(link => {
                    link.setAttribute('role', 'tab');
                    const toggleIcon = link.querySelector('.toggle-icon');
                    if (toggleIcon) {
                        link.removeChild(toggleIcon);
                    }
                });
                
                // Ensure the active tab is shown in desktop view
                this._activateTab(this.activeIndex);
            }
        }
    }

    _activateTab(index) {
        // Remove active class from all links and hide all contents
        this._deactivateAllTabs();
        
        // Add active class to the selected link and show its content
        this.links[index].classList.add('active');
        this.contents[index].classList.add('active');
        this.contents[index].classList.remove('hidden');
        
        // Update the toggle icon (for mobile accordion)
        if (this.isMobile) {
            const toggleIcon = this.links[index].querySelector('.toggle-icon');
            if (toggleIcon) toggleIcon.innerHTML = '−'; // Minus for active
        }
        
        // Update accessibility attributes
        this.links[index].setAttribute('aria-expanded', 'true');
        
        // Update active index
        this.activeIndex = index;
    }
    
    _deactivateTab(index) {
        this.links[index].classList.remove('active');
        this.contents[index].classList.remove('active');
        this.contents[index].classList.add('hidden');
        
        // Update the toggle icon (for mobile accordion)
        if (this.isMobile) {
            const toggleIcon = this.links[index].querySelector('.toggle-icon');
            if (toggleIcon) toggleIcon.innerHTML = '+'; // Plus for inactive
        }
        
        // Update accessibility attributes
        this.links[index].setAttribute('aria-expanded', 'false');
    }
    
    _deactivateAllTabs() {
        this.links.forEach((link, i) => {
            link.classList.remove('active');
            link.setAttribute('aria-expanded', 'false');
            
            // Update the toggle icon (for mobile accordion)
            if (this.isMobile) {
                const toggleIcon = link.querySelector('.toggle-icon');
                if (toggleIcon) toggleIcon.innerHTML = '+'; // Plus for inactive
            }
        });
        
        this.contents.forEach(content => {
            content.classList.remove('active');
            content.classList.add('hidden');
        });
    }
    
    _toggleAccordionItem(index) {
        // Toggle active state of the accordion item
        const isActive = this.links[index].classList.contains('active');
        
        if (isActive) {
            // Close the item
            this._deactivateTab(index);
            this.activeIndex = null;
        } else {
            // Open the item
            this._activateTab(index);
        }
    }
}

// Initialize the tabs when DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
    new TabsPurpose();
});




