class SwiperSlider {
    constructor() {
        this._swiper();
        this._swiper_simple();
    }
    _swiper() {

        if (document.querySelector('.main-swiper')) {
            // Declarar las funciones primero
            // Función para generar la paginación numérica
            const generateNumberPagination = (swiper) => {
                const totalSlides = swiper.slides.length;
                const paginationContainer = document.querySelector('.swiper-number-pagination');

                // Limpiar cualquier contenido previo
                paginationContainer.innerHTML = '';

                // Generar los números de página
                for (let i = 0; i < totalSlides; i++) {
                    const pageNumber = (i + 1).toString().padStart(2, '0');
                    const pageItem = document.createElement('div');
                    pageItem.className = 'swiper-number-pagination__item';
                    pageItem.setAttribute('data-index', i);
                    pageItem.textContent = pageNumber;

                    // Establecer el elemento activo inicial
                    if (i === 0) {
                        pageItem.classList.add('active');
                    }

                    // Agregar el evento de clic para navegar al slide
                    pageItem.addEventListener('click', () => {
                        mainSwiper.slideTo(i);
                    });

                    paginationContainer.appendChild(pageItem);
                }
            };

            // Función para actualizar la paginación numérica
            const updateNumberPagination = (swiper) => {
                const activeIndex = swiper.activeIndex;
                const pageItems = document.querySelectorAll('.swiper-number-pagination__item');

                pageItems.forEach((item, index) => {
                    if (index === activeIndex) {
                        item.classList.add('active');
                    } else {
                        item.classList.remove('active');
                    }
                });
            };

            // Inicializar el swiper
            const mainSwiper = new Swiper('.main-swiper', {
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 1000,
                grabCursor: true,
                watchSlidesProgress: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    type: 'bullets'
                },
                on: {
                    init: function (swiper) {
                        // Generar la paginación numérica dinámicamente
                        generateNumberPagination(swiper);
                        updateNumberPagination(swiper);
                    },
                    slideChange: function (swiper) {
                        updateNumberPagination(swiper);
                    }
                }
            });
        }
    }
    _swiper_simple() {

            // Declarar las funciones primero
            // Función para generar la paginación numérica
            const generateNumberPagination = (swiper) => {
                const totalSlides = swiper.slides.length;
                const paginationContainer = document.querySelector('.swiper-number-pagination');

                // Limpiar cualquier contenido previo
                paginationContainer.innerHTML = '';

                // Generar los números de página
                for (let i = 0; i < totalSlides; i++) {
                    const pageNumber = (i + 1).toString().padStart(2, '0');
                    const pageItem = document.createElement('div');
                    pageItem.className = 'swiper-number-pagination__item';
                    pageItem.setAttribute('data-index', i);
                    pageItem.textContent = pageNumber;

                    // Establecer el elemento activo inicial
                    if (i === 0) {
                        pageItem.classList.add('active');
                    }

                    // Agregar el evento de clic para navegar al slide
                    pageItem.addEventListener('click', () => {
                        mainSwiper.slideTo(i);
                    });

                    paginationContainer.appendChild(pageItem);
                }
            };

            // Función para actualizar la paginación numérica
            const updateNumberPagination = (swiper) => {
                const activeIndex = swiper.activeIndex;
                const pageItems = document.querySelectorAll('.swiper-number-pagination__item');

                pageItems.forEach((item, index) => {
                    if (index === activeIndex) {
                        item.classList.add('active');
                    } else {
                        item.classList.remove('active');
                    }
                });
            };

            // Inicializar el swiper
            const mainSwiper = new Swiper('.main-swiper-simple', {
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 1000,
                grabCursor: true,
                watchSlidesProgress: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: true
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    type: 'bullets'
                },
                on: {
                    init: function (swiper) {
                        // Generar la paginación numérica dinámicamente
                        generateNumberPagination(swiper);
                        updateNumberPagination(swiper);
                    },
                    slideChange: function (swiper) {
                        updateNumberPagination(swiper);
                    }
                }
            });
    }
}