class SwiperBlog {
    constructor() {
        this._swiper();
    }
    _swiper() {
        if (document.querySelector('.blog-swiper')) {
            const blogSwiper = new Swiper('.blog-swiper', {
                slidesPerView: 'auto',
                spaceBetween: 20,
                centeredSlides: false,
                loop: false,
                speed: 800,
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                    snapOnRelease: true,
                    hide: false
                },
                breakpoints: {
                    576: {
                        slidesPerView: 1.5,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 2.5,
                        spaceBetween: 25
                    },
                    1199: {
                        slidesPerView: 3,
                        spaceBetween: 25
                    }
                }
            });
        }   
    }
}




