class AnimationsHome {
  constructor() {
    this._bgDarkSections();
    this._bgDarkSlider();
    //this._backgroundHome();
    this._moveTitle();
    this._bgMainSlider();
    this._mediaQuery();
  }

  // CAMBIAR COLOR BACKGROUND SECTIONS
  _bgDarkSections() {
    const elementBody = document.querySelector('body');
    const sections = document.querySelectorAll('.page-wrapper__content section');
    

    sections.forEach(section => {
      if (section.classList.contains('logo-blanco')) {
        ScrollTrigger.create({
          trigger: section,
          start: 'top top',
          end: 'bottom top',
          onEnter: () => elementBody.classList.add('bg-dark'),
          onEnterBack: () => elementBody.classList.add('bg-dark'),
          onLeave: () => elementBody.classList.remove('bg-dark'),
          onLeaveBack: () => elementBody.classList.remove('bg-dark'),
        });
      }
    });
  }

  // CAMBIAR COLOR BACKGROUND SLIDER
  _bgDarkSlider() {
    const elementBody = document.querySelector('body');
    const mainSlider = document.querySelector('#main-slider .swiper-container');

    if (mainSlider) {
      const initCheck = setInterval(() => {
        const swiper = mainSlider.swiper;

        if (swiper && swiper.initialized) {
          clearInterval(initCheck);

          const checkActiveSlide = () => {
            const activeSlide = mainSlider.querySelector('.swiper-slide-active');
            if (activeSlide?.classList.contains('logo-blanco')) {
              elementBody.classList.add('bg-dark');
            } else {
              elementBody.classList.remove('bg-dark');
            }
          };

          setTimeout(checkActiveSlide, 0);

          swiper.on('slideChange', checkActiveSlide);
          swiper.on('transitionEnd', checkActiveSlide);
          swiper.on('realIndexChange', checkActiveSlide);
        }
      }, 50);

      setTimeout(() => clearInterval(initCheck), 3000);
    }
  }

  _backgroundHome() {
    const elementBody = document.querySelector('body');
    function backgroundBody(state=true) {
      if (state) {
        elementBody.classList.add('bg-dark')
      }else{
        elementBody.classList.remove('bg-dark')
      }
    };

    ScrollTrigger.create({
      trigger: '#about',
      start: 'center top',
      endTrigger: '#service',
      end: 'bottom center',

      onEnter: () => backgroundBody(),
      onEnterBack: () => backgroundBody(),
      onLeave: () => backgroundBody(false),
      onLeaveBack: () => backgroundBody(false),
    });

    

  }

  // MOVER TEXTO
  _moveTitle() {
    gsap.fromTo("#service h1", {
      duration: 1,
      xPercent: 200,
      opacity: 0,
      ease: 'slow',
    }, {
      xPercent: 0,
      opacity: 1,
      ease: 'slow',
      scrollTrigger: {
        trigger: "#service",
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      }
    });
  }


  // MOVER BG
  _bgMainSlider() {
    gsap.to('#main-slider .parallax', {
      yPercent: -20,
      opacity: 0.8,
      scale: 1.25,
      scrollTrigger: {
        trigger: "#main-slider",
        start: 'top top+=1',
        end: 'bottom top',
        scrub: true,
      }
    })

  }

  // MOVER IMG ESTUDIO
  _imageMove() {
    gsap.to('#about .img-move', {
      xPercent: 20,
      yPercent: 20,
      scrollTrigger: {
        trigger: '#about .col-move',
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      }
    })


  }

  // MEDIA JQUERY - aqui se inicia ImageMove
  _mediaQuery() {

    //define breakpoint
    const mq = window.matchMedia("(min-width:768px)");
    //add change lister to this breakpoint
    mq.addListener(HandleWidthChange);
    // first page load
    HandleWidthChange(mq);

    // media query change
    function HandleWidthChange(mq) {

      // check if we are on the right breakpoint
      if (mq.matches) {

        //setup animation gsap
        imageMove()

      } else {
        // width is less than 768px
        
        //gsap.killTweensOf("");

      }
    }

  }

}


// MOVER IMAGEN
function imageMove() {
  gsap.to('#about .img-move', {
    xPercent: 20,
    yPercent: 20,
    scrollTrigger: {
      trigger: '#about .col-move',
      start: 'top top',
      end: 'bottom top',
      scrub: true,
    }
  })
}

window.addEventListener('load', function () {
  //imageMove() la iniciaremos por funcion mediaquery
});




